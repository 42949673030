import { defineComponent, ref, toRefs, computed, watchEffect } from "@vue/composition-api";
import { API } from "@/common-http.js";
import { secondsToTimestamp } from "@/util/timestamp";
import { ToastStyle } from "@/models/toast";
import { QueueMode } from "common/models/types";
import api from "@/util/api";
import { useStore } from "@/util/vuex-workaround";
import { i18n } from "@/i18n";
import toast from "@/util/toast";
const VideoQueueItem = defineComponent({
    name: "VideoQueueItem",
    props: {
        item: { type: Object, required: true },
        isPreview: { type: Boolean, default: false },
        index: { type: Number, required: false },
    },
    setup(props) {
        let { item, isPreview, index } = toRefs(props);
        const store = useStore();
        let isLoadingAdd = ref(false);
        let isLoadingVote = ref(false);
        let hasBeenAdded = ref(false);
        let thumbnailHasError = ref(false);
        let hasError = ref(false);
        let voted = ref(false);
        let videoLength = computed(() => { var _a, _b; return secondsToTimestamp((_b = (_a = item.value) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0); });
        let videoStartAt = computed(() => { var _a, _b; return secondsToTimestamp((_b = (_a = item.value) === null || _a === void 0 ? void 0 : _a.startAt) !== null && _b !== void 0 ? _b : 0); });
        let thumbnailSource = computed(() => {
            return !thumbnailHasError.value && item.value.thumbnail
                ? item.value.thumbnail
                : require("@/assets/placeholder.svg");
        });
        let votes = computed(() => {
            var _a, _b;
            const store = useStore();
            return (_b = (_a = store.state.room.voteCounts) === null || _a === void 0 ? void 0 : _a.get(item.value.service + item.value.id)) !== null && _b !== void 0 ? _b : 0;
        });
        function updateHasBeenAdded() {
            if (store.state.room.currentSource &&
                item.value.id === store.state.room.currentSource.id &&
                item.value.service === store.state.room.currentSource.service) {
                hasBeenAdded.value = true;
                return;
            }
            for (let video of store.state.room.queue) {
                if (item.value.id === video.id && item.value.service === video.service) {
                    hasBeenAdded.value = true;
                    return;
                }
            }
            hasBeenAdded.value = false;
        }
        function getPostData() {
            let data = {
                service: item.value.service,
                id: item.value.id,
            };
            return data;
        }
        async function addToQueue() {
            isLoadingAdd.value = true;
            try {
                let resp = await API.post(`/room/${store.state.room.name}/queue`, getPostData());
                hasError.value = !resp.data.success;
                hasBeenAdded.value = true;
                toast.add({
                    style: ToastStyle.Success,
                    content: i18n.t("video-queue-item.messages.video-added").toString(),
                    duration: 5000,
                });
            }
            catch (e) {
                hasError.value = true;
                toast.add({
                    style: ToastStyle.Error,
                    content: e.response.data.error.message,
                    duration: 6000,
                });
            }
            isLoadingAdd.value = false;
        }
        async function removeFromQueue() {
            isLoadingAdd.value = true;
            try {
                let resp = await API.delete(`/room/${store.state.room.name}/queue`, {
                    data: getPostData(),
                });
                hasError.value = !resp.data.success;
                toast.add({
                    style: ToastStyle.Success,
                    content: i18n.t("video-queue-item.messages.video-removed").toString(),
                    duration: 5000,
                });
            }
            catch (e) {
                hasError.value = true;
                toast.add({
                    style: ToastStyle.Error,
                    content: e.response.data.error.message,
                    duration: 6000,
                });
            }
            isLoadingAdd.value = false;
        }
        async function vote() {
            isLoadingVote.value = true;
            try {
                let resp;
                if (!voted.value) {
                    resp = await API.post(`/room/${store.state.room.name}/vote`, getPostData());
                    voted.value = true;
                }
                else {
                    resp = await API.delete(`/room/${store.state.room.name}/vote`, {
                        data: getPostData(),
                    });
                    voted.value = false;
                }
                hasError.value = !resp.data.success;
            }
            catch (e) {
                hasError.value = true;
                toast.add({
                    style: ToastStyle.Error,
                    content: e.response.data.error.message,
                    duration: 6000,
                });
            }
            isLoadingVote.value = false;
        }
        function playNow() {
            api.playNow(item.value);
        }
        /**
         * Moves the video to the top of the queue.
         */
        function moveToTop() {
            api.queueMove(index.value, 0);
        }
        /**
         * Moves the video to the bottom of the queue.
         */
        function moveToBottom() {
            api.queueMove(index.value, store.state.room.queue.length - 1);
        }
        function onThumbnailError() {
            thumbnailHasError.value = true;
        }
        watchEffect(() => {
            updateHasBeenAdded();
        });
        return {
            isLoadingAdd,
            isLoadingVote,
            hasBeenAdded,
            thumbnailHasError,
            hasError,
            voted,
            videoLength,
            videoStartAt,
            thumbnailSource,
            votes,
            addToQueue,
            removeFromQueue,
            vote,
            playNow,
            moveToTop,
            moveToBottom,
            onThumbnailError,
            QueueMode,
            store,
        };
    },
});
export default VideoQueueItem;
