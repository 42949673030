import { defineComponent, ref, onMounted, watch } from "@vue/composition-api";
const urlRegex = /(https?:\/\/[^\s]+)/;
const ProcessedText = defineComponent({
    name: "ProcessedText",
    props: {
        text: { type: String, required: true },
    },
    emits: ["link-click"],
    setup({ text }, { emit }) {
        let content = ref([]);
        function onLinkClick(e, link) {
            e.preventDefault();
            e.stopPropagation();
            emit("link-click", link);
        }
        function processText() {
            content.value = [];
            if (!text) {
                return;
            }
            let match;
            let index = 0;
            let loop = 0;
            while ((match = urlRegex.exec(text.substring(index))) !== null) {
                // console.log("msg:", this.text, "match", match, "content", this.content);
                if (match.index > index) {
                    content.value.push({
                        type: "text",
                        text: text.slice(index, index + match.index),
                    });
                }
                content.value.push({ type: "link", text: match[0] });
                index += match.index + match[0].length;
                loop++;
                if (loop > 10) {
                    break;
                }
            }
            if (index < text.length) {
                content.value.push({ type: "text", text: text.substring(index) });
            }
        }
        onMounted(() => {
            processText();
        });
        watch(() => text, () => {
            processText();
        });
        return {
            content,
            onLinkClick,
            processText,
        };
    },
});
export default ProcessedText;
