import ProcessedText from "@/components/ProcessedText.vue";
import api from "@/util/api";
import { defineComponent, onUpdated, ref, nextTick } from "@vue/composition-api";
const MSG_SHOW_TIMEOUT = 20000;
let inputValue = ref("");
let stickToBottom = ref(true);
/**
 * When chat is activated, all messages are shown. and the
 * user can scroll through message history, type in chat, etc.
 * When chat is NOT activated, when messages are received,
 * they appear and fade away after `MSG_SHOW_TIMEOUT` ms.
 */
let activated = ref(false);
let deactivateOnBlur = ref(false);
/**
 * All past chat messages. They are are no longer
 * shown when deactivated.
 */
let chatMessagePast = ref([]);
/**
 * All recent chat messages that are currently shown when deactivated.
 * They will fade away after `MSG_SHOW_TIMEOUT` ms, and moved into `chatMessagePast`.
 */
let chatMessageRecent = ref([]);
let messages = ref();
let chatInput = ref();
function focusChatInput() {
    var _a;
    (_a = chatInput.value) === null || _a === void 0 ? void 0 : _a.focus();
}
function isActivated() {
    return activated.value;
}
async function setActivated(value, manual = false) {
    var _a;
    activated.value = value;
    if (value) {
        if (manual) {
            deactivateOnBlur.value = false;
        }
        else {
            deactivateOnBlur.value = true;
        }
        await nextTick();
        focusChatInput();
    }
    else {
        (_a = chatInput.value) === null || _a === void 0 ? void 0 : _a.blur();
    }
}
function onChatReceived(msg) {
    chatMessageRecent.value.push(msg);
    setTimeout(expireChatMessage, MSG_SHOW_TIMEOUT);
}
function expireChatMessage() {
    chatMessagePast.value.push(chatMessageRecent.value.splice(0, 1)[0]);
}
/**
 * Performs the necessary actions to enact the stickToBottom behavior.
 */
function enforceStickToBottom() {
    const div = messages.value;
    if (stickToBottom.value) {
        div.scrollTop = div.scrollHeight;
    }
}
const Chat = defineComponent({
    name: "Chat",
    components: {
        ProcessedText,
    },
    emits: ["link-click"],
    setup() {
        function onInputKeyDown(e) {
            if (e.key === "Enter") {
                e.preventDefault();
                if (inputValue.value.trim() !== "") {
                    api.chat(inputValue.value);
                }
                inputValue.value = "";
                stickToBottom.value = true;
                setActivated(false);
            }
            else if (e.key === "Escape") {
                e.preventDefault();
                setActivated(false);
            }
        }
        function onScroll() {
            const div = messages.value;
            const distToBottom = div.scrollHeight - div.clientHeight - div.scrollTop;
            stickToBottom.value = distToBottom === 0;
        }
        onUpdated(enforceStickToBottom);
        return {
            inputValue,
            stickToBottom,
            activated,
            chatMessagePast,
            chatMessageRecent,
            deactivateOnBlur,
            onInputKeyDown,
            onScroll,
            focusChatInput,
            isActivated,
            setActivated,
            onChatReceived,
            enforceStickToBottom,
            messages,
            chatInput,
        };
    },
});
export default Chat;
