import { defineComponent, toRefs } from "@vue/composition-api";
const ClosedCaptionsSwitcher = defineComponent({
    name: "ClosedCaptionsSwitcher",
    emits: ["enable-cc", "cc-track"],
    props: {
        supported: { type: Boolean, default: true },
        tracks: { type: Array, default: [] },
    },
    setup(props, { emit }) {
        let { tracks } = toRefs(props);
        function setCaptionsEnabled(value) {
            emit("enable-cc", value);
        }
        function setCaptionsTrack(value) {
            emit("cc-track", value);
        }
        return {
            setCaptionsEnabled,
            setCaptionsTrack,
        };
    },
});
export default ClosedCaptionsSwitcher;
