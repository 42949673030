var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"mt-2 video",attrs:{"hover":""}},[_c('div',{staticClass:"img-container"},[_c('v-img',{attrs:{"src":_vm.thumbnailSource,"lazy-src":require('@/assets/placeholder.svg'),"aspect-ratio":"1.8"},on:{"error":_vm.onThumbnailError}},[(!_vm.isPreview && _vm.store.state.room.queueMode !== _vm.QueueMode.Vote)?_c('span',{staticClass:"drag-handle"},[_c('v-icon',[_vm._v("fas fa-align-justify")])],1):_vm._e(),_c('span',{staticClass:"video-length"},[_vm._v(_vm._s(_vm.videoLength))])])],1),_c('div',{staticClass:"meta-container"},[_c('div',[_c('div',{staticClass:"video-title",attrs:{"no-gutters":""}},[_vm._v(_vm._s(_vm.item.title))]),_c('div',{staticClass:"description text-truncate",attrs:{"no-gutters":""}},[_vm._v(_vm._s(_vm.item.description))]),(_vm.item.service === 'googledrive')?_c('div',{staticClass:"experimental"},[_vm._v(" "+_vm._s(_vm.$t("video-queue-item.experimental"))+" ")]):_vm._e(),(_vm.item.startAt !== undefined)?_c('span',{staticClass:"video-start-at"},[_vm._v(" "+_vm._s(_vm.$t("video-queue-item.start-at", { timestamp: _vm.videoStartAt }))+" ")]):_vm._e()])]),_c('div',{staticClass:"d-flex",staticStyle:{"justify-content":"center","flex-direction":"column"}},[_c('div',{staticClass:"button-container"},[(!_vm.isPreview && _vm.store.state.room.queueMode === _vm.QueueMode.Vote)?_c('v-btn',{staticClass:"button-with-icon",attrs:{"loading":_vm.isLoadingVote,"color":_vm.voted ? 'red' : 'green'},on:{"click":_vm.vote}},[_c('span',[_vm._v(_vm._s(_vm.votes))]),_c('v-icon',[_vm._v("fas fa-thumbs-up")]),_c('span',{staticClass:"vote-text"},[_vm._v(_vm._s(_vm.item.voted ? "Unvote" : "Vote"))])],1):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.store.state.room.queueMode !== _vm.QueueMode.Vote)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.playNow}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fas fa-play")])],1):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("video.playnow-explanation")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isPreview && _vm.store.state.room.queueMode !== _vm.QueueMode.Dj)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","loading":_vm.isLoadingAdd},on:{"click":_vm.addToQueue}},'v-btn',attrs,false),on),[(_vm.hasError)?_c('v-icon',[_vm._v("fas fa-exclamation")]):(_vm.hasBeenAdded)?_c('v-icon',[_vm._v("fas fa-check")]):_c('v-icon',[_vm._v("fas fa-plus")])],1):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("video.add-explanation")))])]),(!_vm.isPreview && _vm.store.state.room.queueMode !== _vm.QueueMode.Dj)?_c('v-btn',{attrs:{"icon":"","loading":_vm.isLoadingAdd},on:{"click":_vm.removeFromQueue}},[(_vm.hasError)?_c('v-icon',[_vm._v("fas fa-exclamation")]):_c('v-icon',[_vm._v("fas fa-trash")])],1):_vm._e(),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fas fa-ellipsis-v")])],1)]}}])},[_c('v-list',[(_vm.store.state.room.queueMode !== _vm.QueueMode.Vote)?_c('v-list-item',{staticClass:"button-with-icon",on:{"click":_vm.playNow}},[_c('v-icon',[_vm._v("fas fa-play")]),_c('span',[_vm._v(_vm._s(_vm.$t("video.playnow")))])],1):_vm._e(),(
							!_vm.isPreview &&
							_vm.store.state.room.queueMode !== _vm.QueueMode.Vote &&
							_vm.store.state.room.queueMode !== _vm.QueueMode.Dj
						)?_c('v-list-item',{staticClass:"button-with-icon",on:{"click":_vm.moveToTop}},[_c('v-icon',[_vm._v("fas fa-sort-amount-up")]),_c('span',[_vm._v(_vm._s(_vm.$t("video-queue-item.play-next")))])],1):_vm._e(),(!_vm.isPreview && _vm.store.state.room.queueMode !== _vm.QueueMode.Vote)?_c('v-list-item',{staticClass:"button-with-icon",on:{"click":_vm.moveToBottom}},[_c('v-icon',[_vm._v("fas fa-sort-amount-down-alt")]),_c('span',[_vm._v(_vm._s(_vm.$t("video-queue-item.play-last")))])],1):_vm._e(),(_vm.isPreview && _vm.store.state.room.queueMode === _vm.QueueMode.Dj)?_c('v-btn',{attrs:{"icon":"","loading":_vm.isLoadingAdd},on:{"click":_vm.addToQueue}},[(_vm.hasError)?_c('v-icon',[_vm._v("fas fa-exclamation")]):(_vm.hasBeenAdded)?_c('v-icon',[_vm._v("fas fa-check")]):_c('v-icon',[_vm._v("fas fa-plus")]),_c('span',[_vm._v(_vm._s(_vm.$t("video-queue-item.add")))])],1):_vm._e(),(!_vm.isPreview && _vm.store.state.room.queueMode === _vm.QueueMode.Dj)?_c('v-list-item',{staticClass:"button-with-icon",on:{"click":_vm.removeFromQueue}},[_c('v-icon',[_vm._v("fas fa-trash")]),_c('span',[_vm._v(_vm._s(_vm.$t("video-queue-item.remove")))])],1):_vm._e()],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }