import { render, staticRenderFns } from "./Room.vue?vue&type=template&id=587b025e&scoped=true&"
import script from "./Room.vue?vue&type=script&lang=js&"
export * from "./Room.vue?vue&type=script&lang=js&"
import style0 from "./Room.vue?vue&type=style&index=0&id=587b025e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "587b025e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VListItem } from 'vuetify/lib/components/VList';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCard,VCol,VContainer,VFooter,VIcon,VLayout,VListItem,VOverlay,VResponsive,VRow,VSubheader,VTab,VTabItem,VTabs,VTabsItems,VTooltip})
