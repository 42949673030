var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"room-settings",staticStyle:{"margin":"12px"}},[_c('v-form',{on:{"submit":_vm.submitRoomSettings}},[_c('v-text-field',{attrs:{"label":_vm.$t('room-settings.title'),"loading":_vm.isLoadingRoomSettings,"disabled":!_vm.granted('configure-room.set-title')},model:{value:(_vm.inputRoomSettings.title),callback:function ($$v) {_vm.$set(_vm.inputRoomSettings, "title", $$v)},expression:"inputRoomSettings.title"}}),_c('v-text-field',{attrs:{"label":_vm.$t('room-settings.description'),"loading":_vm.isLoadingRoomSettings,"disabled":!_vm.granted('configure-room.set-description')},model:{value:(_vm.inputRoomSettings.description),callback:function ($$v) {_vm.$set(_vm.inputRoomSettings, "description", $$v)},expression:"inputRoomSettings.description"}}),_c('v-select',{attrs:{"label":_vm.$t('room-settings.visibility'),"items":[
				{ text: _vm.$t('room-settings.public'), value: 'public' },
				{ text: _vm.$t('room-settings.unlisted'), value: 'unlisted' } ],"loading":_vm.isLoadingRoomSettings,"disabled":!_vm.granted('configure-room.set-visibility'),"data-cy":"select-visibility"},model:{value:(_vm.inputRoomSettings.visibility),callback:function ($$v) {_vm.$set(_vm.inputRoomSettings, "visibility", $$v)},expression:"inputRoomSettings.visibility"}}),_c('v-select',{attrs:{"label":_vm.$t('room-settings.queue-mode'),"items":[
				{
					name: _vm.$t('room-settings.manual'),
					value: _vm.QueueMode.Manual,
					description: _vm.$t('room-settings.manual-hint'),
				},
				{
					name: _vm.$t('room-settings.vote'),
					value: _vm.QueueMode.Vote,
					description: _vm.$t('room-settings.vote-hint'),
				},
				{
					name: _vm.$t('room-settings.loop'),
					value: _vm.QueueMode.Loop,
					description: _vm.$t('room-settings.loop-hint'),
				},
				{
					name: _vm.$t('room-settings.dj'),
					value: _vm.QueueMode.Dj,
					description: _vm.$t('room-settings.dj-hint'),
				} ],"loading":_vm.isLoadingRoomSettings,"disabled":!_vm.granted('configure-room.set-queue-mode'),"data-cy":"select-queueMode"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(data.item.name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(data.item.description))])],1)]}},{key:"selection",fn:function(data){return [_c('v-list-item-title',[_vm._v(_vm._s(data.item.name))])]}}]),model:{value:(_vm.inputRoomSettings.queueMode),callback:function ($$v) {_vm.$set(_vm.inputRoomSettings, "queueMode", $$v)},expression:"inputRoomSettings.queueMode"}}),_c('v-checkbox',{attrs:{"label":_vm.$t('room-settings.auto-skip-text')},model:{value:(_vm.inputRoomSettings.autoSkipSegments),callback:function ($$v) {_vm.$set(_vm.inputRoomSettings, "autoSkipSegments", $$v)},expression:"inputRoomSettings.autoSkipSegments"}}),(
				!_vm.$store.state.room.isTemporary &&
				_vm.$store.state.user &&
				_vm.$store.state.room.hasOwner
			)?_c('PermissionsEditor',{attrs:{"current-role":_vm.$store.state.users.you.role},model:{value:(_vm.inputRoomSettings.grants),callback:function ($$v) {_vm.$set(_vm.inputRoomSettings, "grants", $$v)},expression:"inputRoomSettings.grants"}}):(_vm.$store.state.room.isTemporary)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("room-settings.permissions-not-available"))+" ")]):(!_vm.$store.state.room.hasOwner)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("room-settings.room-needs-owner"))+" "),(!_vm.$store.state.user)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("room-settings.login-to-claim"))+" ")]):_vm._e()]):_c('div',[_vm._v(" "+_vm._s(_vm.$t("room-settings.arent-able-to-modify-permissions"))+" ")]),_c('div',{staticClass:"submit"},[(!_vm.$store.state.room.isTemporary && !_vm.$store.state.room.hasOwner)?_c('v-btn',{attrs:{"large":"","block":"","color":"blue","disabled":!_vm.$store.state.user,"role":"submit"},on:{"click":_vm.claimOwnership}},[_vm._v("Claim Room")]):_vm._e(),_c('v-btn',{attrs:{"x-large":"","block":"","role":"submit","loading":_vm.isLoadingRoomSettings},on:{"click":_vm.submitRoomSettings}},[_vm._v(_vm._s(_vm.$t("actions.save")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }