import { defineComponent, ref, watch } from "@vue/composition-api";
import { useStore } from "@/util/vuex-workaround";
import { RoomLayoutMode, Theme } from "@/stores/settings";
import _ from "lodash";
const EXCLUDED = ["volume", "locale"];
export const ClientSettingsDialog = defineComponent({
    name: "ClientSettingsDialog",
    setup() {
        let show = ref(false);
        const store = useStore();
        let settings = ref(loadSettings());
        function loadSettings() {
            let copy = _.cloneDeep(store.state.settings);
            let filtered = _.omit(copy, EXCLUDED);
            return filtered;
        }
        function applySettings() {
            store.commit("settings/UPDATE", settings.value);
            show.value = false;
        }
        function cancelSettings() {
            show.value = false;
        }
        watch(show, () => {
            settings.value = loadSettings();
        });
        return {
            show,
            settings,
            applySettings,
            cancelSettings,
            RoomLayoutMode,
            Theme,
        };
    },
});
export default ClientSettingsDialog;
