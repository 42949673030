import _ from "lodash";
import PermissionsEditor from "@/components/PermissionsEditor.vue";
import { ToastStyle } from "@/models/toast";
import { API } from "@/common-http.js";
import { Visibility, QueueMode } from "common/models/types";
import { granted } from "@/util/grants";
import toast from "@/util/toast";
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import { useStore } from "@/util/vuex-workaround";
import { i18n } from "@/i18n";
const RoomSettingsForm = defineComponent({
    name: "RoomSettingsForm",
    components: {
        PermissionsEditor,
    },
    setup() {
        const store = useStore();
        let isLoadingRoomSettings = ref(false);
        let inputRoomSettings = ref({
            title: "",
            description: "",
            visibility: Visibility.Public,
            queueMode: QueueMode.Manual,
            grants: {},
            autoSkipSegments: true,
        });
        onMounted(async () => {
            await loadRoomSettings();
        });
        async function loadRoomSettings() {
            // we have to make an API request becuase visibility is not sent in sync messages.
            isLoadingRoomSettings.value = true;
            let res = await API.get(`/room/${store.state.room.name}`);
            isLoadingRoomSettings.value = false;
            if (res.data.permissions && !res.data.grants) {
                res.data.grants = res.data.permissions;
            }
            inputRoomSettings.value = _.pick(res.data, "title", "description", "visibility", "queueMode", "grants", "autoSkipSegments");
        }
        function getRoomSettingsSubmit() {
            const propsToGrants = {
                title: "set-title",
                description: "set-description",
                visibility: "set-visibility",
                queueMode: "set-queue-mode",
                autoSkipSegments: "set-auto-skip",
            };
            let blocked = [];
            for (let prop of Object.keys(propsToGrants)) {
                if (!granted(`configure-room.${propsToGrants[prop]}`)) {
                    blocked.push(prop);
                }
            }
            if (store.state.room.isTemporary) {
                blocked.push("grants");
            }
            return _.omit(inputRoomSettings.value, blocked);
        }
        /** Take room settings from the UI and submit them to the server. */
        async function submitRoomSettings() {
            isLoadingRoomSettings.value = true;
            try {
                await API.patch(`/room/${store.state.room.name}`, getRoomSettingsSubmit());
                toast.add({
                    style: ToastStyle.Success,
                    content: i18n.t("room-settings.settings-applied").toString(),
                    duration: 4000,
                });
            }
            catch (e) {
                console.log(e);
                toast.add({
                    style: ToastStyle.Error,
                    content: e.response.data.error.message,
                    duration: 6000,
                });
            }
            isLoadingRoomSettings.value = false;
        }
        async function claimOwnership() {
            isLoadingRoomSettings.value = true;
            try {
                await API.patch(`/room/${store.state.room.name}`, {
                    claim: true,
                });
                toast.add({
                    style: ToastStyle.Success,
                    content: i18n
                        .t("room-settings.now-own-the-room", {
                        room: store.state.room.name,
                    })
                        .toString(),
                    duration: 4000,
                });
            }
            catch (e) {
                console.log(e);
                toast.add({
                    style: ToastStyle.Error,
                    content: e.response.data.error.message,
                    duration: 6000,
                });
            }
            isLoadingRoomSettings.value = false;
        }
        return {
            isLoadingRoomSettings,
            inputRoomSettings,
            loadRoomSettings,
            getRoomSettingsSubmit,
            submitRoomSettings,
            claimOwnership,
            store,
            granted,
            Visibility,
            QueueMode,
        };
    },
});
export default RoomSettingsForm;
