var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"video-add"},[_c('v-row',[_c('v-textarea',{attrs:{"clearable":"","auto-grow":"","rows":"1","placeholder":_vm.$t('add-preview.placeholder'),"loading":_vm.isLoadingAddPreview,"data-cy":"add-preview-input"},on:{"keydown":_vm.onInputAddPreviewKeyDown,"focus":_vm.onFocusHighlightText},model:{value:(_vm.inputAddPreview),callback:function ($$v) {_vm.inputAddPreview=$$v},expression:"inputAddPreview"}})],1),_c('v-row',[(!_vm.production)?_c('div',_vm._l((_vm.testVideos),function(v,idx){return _c('v-btn',{key:idx,on:{"click":function($event){return _vm.postTestVideo(idx)}}},[_vm._v(_vm._s(v[0]))])}),1):_vm._e(),(_vm.videos.length > 1)?_c('v-btn',{attrs:{"loading":_vm.isLoadingAddAll,"disabled":_vm.isLoadingAddAll},on:{"click":function($event){return _vm.addAllToQueue()}}},[_vm._v(_vm._s(_vm.$t("add-preview.add-all")))]):_vm._e()],1),(_vm.isLoadingAddPreview)?_c('v-row',{staticClass:"mt-6",attrs:{"justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):_vm._e(),(!_vm.isLoadingAddPreview)?_c('v-row',{staticClass:"mt-6",attrs:{"justify":"center"}},[(_vm.hasAddPreviewFailed)?_c('div',[_vm._v(" "+_vm._s(_vm.videosLoadFailureText)+" ")]):_vm._e(),(
				_vm.videos.length == 0 &&
				_vm.inputAddPreview.length > 0 &&
				!_vm.hasAddPreviewFailed &&
				!_vm.isAddPreviewInputUrl
			)?_c('v-container',{attrs:{"fill-height":""}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$t("add-preview.search-for", { search: _vm.inputAddPreview }))),_c('br'),_c('v-btn',{on:{"click":_vm.requestAddPreviewExplicit}},[_vm._v(_vm._s(_vm.$t("add-preview.search")))])],1)],1)],1):(_vm.inputAddPreview.length === 0)?_c('v-container',[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('div',{staticClass:"add-video-helper"},[_c('h1',[_vm._v(_vm._s(_vm.$t("add-preview.title")))]),_c('h3',[_vm._v(_vm._s(_vm.$t("add-preview.single-videos")))]),_c('ul',[_c('li',[_c('ProcessedText',{attrs:{"text":_vm.$t('add-preview.platforms.youtube-videos', {
										url: 'https://youtube.com/watch?v=LP8GRjv6AIo',
									})},on:{"link-click":_vm.setAddPreviewText}})],1),_c('li',[_c('ProcessedText',{attrs:{"text":_vm.$t('add-preview.platforms.vimeo-videos', {
										url: 'https://vimeo.com/94338566',
									})},on:{"link-click":_vm.setAddPreviewText}})],1),_c('li',[_c('ProcessedText',{attrs:{"text":_vm.$t('add-preview.platforms.dailymotion-videos', {
										url: 'https://dailymotion.com/video/x31i1so',
									})},on:{"link-click":_vm.setAddPreviewText}})],1),_c('li',[_c('ProcessedText',{attrs:{"text":_vm.$t('add-preview.platforms.any-mp4-videos', {
										url: 'https://vjs.zencdn.net/v/oceans.mp4',
									})},on:{"link-click":_vm.setAddPreviewText}})],1)]),_c('h3',[_vm._v(_vm._s(_vm.$t("add-preview.playlists")))]),_c('ul',[_c('li',[_c('ProcessedText',{attrs:{"text":_vm.$t('add-preview.platforms.youtube-playlists', {
										url: 'https://youtube.com/playlist?list=PLv-kM7bcufALqOQvMsrVCQCEL1pIWScoQ',
									})},on:{"link-click":_vm.setAddPreviewText}})],1),_c('li',[_c('ProcessedText',{attrs:{"text":_vm.$t('add-preview.platforms.youtube-channels', {
										url: 'https://youtube.com/channel/UCI1XS_GkLGDOgf8YLaaXNRA',
									})},on:{"link-click":_vm.setAddPreviewText}})],1),_c('li',[_c('ProcessedText',{attrs:{"text":_vm.$t('add-preview.platforms.subreddits', {
										url: 'https://reddit.com/r/youtubehaiku/',
									})},on:{"link-click":_vm.setAddPreviewText}})],1)]),_c('span',[_vm._v(_vm._s(_vm.$t("add-preview.text")))])])])],1):_vm._e()],1):_vm._e(),(_vm.highlightedAddPreviewItem)?_c('div',[_c('VideoQueueItem',{staticStyle:{"margin-bottom":"20px"},attrs:{"item":_vm.highlightedAddPreviewItem,"is-preview":""}}),_c('h4',[_vm._v(_vm._s(_vm.$t("add-preview.playlist")))])],1):_vm._e(),_vm._l((_vm.videos),function(itemdata,index){return _c('VideoQueueItem',{key:index,attrs:{"item":itemdata,"is-preview":""}})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }