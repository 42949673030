import { defineComponent, ref, toRefs, nextTick } from "@vue/composition-api";
const editor = ref();
const editing = ref(false);
const valueDirty = ref();
const display = ref();
const editorWidth = ref(120);
function valueFormatterDefault(value) {
    return value.toString();
}
function valueParserDefault(value) {
    return parseInt(value, 10);
}
/**
 * Provides a value display that can be clicked to edit.
 */
const ClickToEdit = defineComponent({
    name: "ClickToEdit",
    emits: ["change", "input"],
    props: {
        value: {
            type: [String, Number],
            required: true,
        },
        valueFormatter: {
            type: Function,
            default: valueFormatterDefault,
        },
        valueParser: {
            type: Function,
            default: valueParserDefault,
        },
    },
    setup(props, { emit }) {
        const { value } = toRefs(props);
        const valueFormatter = ref(props.valueFormatter);
        const valueParser = ref(props.valueParser);
        async function activate() {
            var _a;
            if (display.value) {
                editorWidth.value = display.value.offsetWidth + 24;
            }
            if (typeof value.value === "number") {
                valueDirty.value = valueFormatter.value(value.value);
            }
            else {
                valueDirty.value = value.value;
            }
            editing.value = true;
            await nextTick();
            (_a = editor.value) === null || _a === void 0 ? void 0 : _a.focus();
        }
        function apply() {
            if (typeof value.value === "number") {
                value.value = valueParser.value(valueDirty.value);
            }
            else {
                value.value = valueDirty.value;
            }
            editing.value = false;
            emit("change", value.value);
            emit("input", value.value);
        }
        function abort() {
            editing.value = false;
        }
        return {
            editor,
            display,
            editing,
            valueDirty,
            editorWidth,
            activate,
            apply,
            abort,
        };
    },
});
export default ClickToEdit;
