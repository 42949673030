import { defineComponent } from "@vue/composition-api";
import draggable from "vuedraggable";
import VideoQueueItem from "@/components/VideoQueueItem.vue";
import api from "@/util/api";
import { granted } from "@/util/grants";
function onQueueDragDrop(e) {
    api.queueMove(e.oldIndex, e.newIndex);
}
const VideoQueue = defineComponent({
    name: "VideoQueue",
    components: {
        draggable,
        VideoQueueItem,
    },
    setup() {
        return {
            onQueueDragDrop,
            api,
            granted,
        };
    },
});
export default VideoQueue;
